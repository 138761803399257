@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-taL {
  text-align: left !important; }

.u-taC {
  text-align: center !important; }
  @media screen and (max-width: 568px) {
    .u-taC--xs-taL {
      text-align: left !important; } }
  @media screen and (max-width: 768px) {
    .u-taC--sm-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1024px) {
    .u-taC--md-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1280px) {
    .u-taC--lg-taL {
      text-align: left !important; } }

.u-taR {
  text-align: right !important; }

.u-only-xs {
  display: none !important; }
  @media screen and (max-width: 568px) {
    .u-only-xs {
      display: block !important; } }

.u-only-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-only-sm {
      display: block !important; } }
  @media screen and (max-width: 568px) {
    .u-only-sm {
      display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important; } }

.u-under-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-under-sm {
      display: block !important; } }

.u-not-under-sm {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .u-not-under-sm {
      display: none !important; } }

.u-overflow-hidden {
  overflow: hidden !important; }

.c-topicspath {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 5px;
  position: relative; }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      text-align: left;
      padding-left: 0px;
      padding-right: 0px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li::after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child::after {
      content: none; }

.l-lowerCaption {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 450px;
  background-image: url("/inc/image/common/bg_lowerCaption.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 768px) {
    .l-lowerCaption {
      height: 350px; } }
  .l-lowerCaption__title {
    color: #333333;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%;
    font-size: 28px;
    line-height: 2em; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        width: 100%;
        padding: 0;
        padding-top: 100px; } }
    .l-lowerCaption__title--sub {
      font-size: .5em;
      display: block;
      line-height: 1em; }
  .l-lowerCaption.is-lang--en .l-lowerCaption__title {
    font-size: 5em; }
    @media screen and (max-width: 768px) {
      .l-lowerCaption.is-lang--en .l-lowerCaption__title {
        font-size: 3em; } }

#reason .p-reason {
  margin-top: 90px;
  margin-bottom: 90px; }
  @media screen and (max-width: 568px) {
    #reason .p-reason {
      margin-top: 0;
      margin-bottom: 0; } }

#privacy .p-analysisTool {
  margin-top: 20px;
  border: 1px solid #000;
  padding: 20px; }
  #privacy .p-analysisTool__heading {
    font-size: 22px;
    margin-bottom: 15px; }
    @media screen and (max-width: 568px) {
      #privacy .p-analysisTool__heading {
        font-size: 18px;
        margin-bottom: 12px; } }
  #privacy .p-analysisTool p:last-child {
    margin-bottom: 0; }

#sitemap .p-sitemapList {
  margin: 0 100px 5px; }
  @media screen and (max-width: 568px) {
    #sitemap .p-sitemapList {
      margin: 0 15px 5px; } }
